<template>
	<div>
		<v-card class="pa-3" v-if="new Array('User','Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
			<v-card-title>Contacts :</v-card-title>
			<v-card-text>
				<ContactTab :profile="profile" :user="user" :notifications="notifications" :currentRoom="currentRoom" />
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
	import ContactTab from '@/components/Contacts/ContactTab.vue'

	export default {
		components: {
      ContactTab,
		},
		name: 'Contacts',
		props: ['user', 'profile', 'notifications', 'config', 'currentRoom'],
		data() {
			return {
				tab: 0,
			}
		},
	}
</script>
