<template>
	<v-row class="mt-3">
		<v-col cols="12" md="6" v-for="contact in contacts" :key="contact.id" class="py-2">
			<v-card outlined class="pa-3 d-flex align-center justify-space-between flex-wrap my-0">
				<span class="d-flex align-center justify-start flex-wrap">
                    <UserAvatar :profile="contact"></UserAvatar>
					<span class="pl-2">
						<b class="ma-0 mx-3" v-text="contact.lastname + ' ' + contact.firstname"></b>
						<p class="ma-0 mx-3" v-text="contact.lastname.toLowerCase() + '.' + contact.firstname.toLowerCase() + '@' + contact.domain.toLowerCase()"></p>
						<p class="ma-0 mx-3" v-text="contact.description"></p>
					</span>
				</span>
				<span class="ml-auto" v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
					<v-btn icon color="secondary" @click="openEditModal(contact)">
						<v-icon>mdi-pencil-outline</v-icon>
					</v-btn>
					<v-btn icon color="error" @click="deleteContact(contact)">
						<v-icon>mdi-delete-outline</v-icon>
					</v-btn>
				</span>
			</v-card>
		</v-col>
		<v-col cols="12" class="pa-3 d-flex justify-end" v-if="new Array('Corrector', 'Animator', 'Admin', 'SuperAdmin').includes(profile.role)">
			<v-btn color="primary" fab @click="openCreationModal">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-col>

        <ContactCreationModal ref="contactCreationModal" :data="creationModalData" :title="modalTitle" @save="handleSaveContact"></ContactCreationModal>
	</v-row>
</template>

<script>
    import logger from "@/assets/functions/logger"
	import Swal from 'sweetalert2/dist/sweetalert2.js'

    import ContactCreationModal from "@/components/Contacts/ContactCreationModal.vue"
    import UserAvatar from "@/components/UserAvatar.vue"

    import Contact from "@/classes/Contact"
    import Mail from "@/classes/Mail"

	export default {
		name: "ContactTab",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
        components: {
            ContactCreationModal,
            UserAvatar,
        },
        data() {
            return {
                contacts: [],
                creationModalData: {
                    id: null,
                    room: null,
                    firstname: "",
                    lastname: "",
                    description: "",
                    domain: "",
                },
                unsub: [],
                modalTitle: "",
            }
        },
        created() {
            this.unsub.push(
                Contact.listenByRoom(this.profile.currentRoom, (contacts) => {
                    this.contacts = contacts
                })
            )
        },
        methods: {
            openCreationModal() {
                this.creationModalData = {
                    id: null,
                    room: this.profile.currentRoom,
                    firstname: "",
                    lastname: "",
                    description: "",
                    domain: "",
                }
                this.modalTitle = "Créer un contact"
                this.$refs.contactCreationModal.open()
            },
            openEditModal(contact) {
                this.creationModalData = {
                    id: contact.id,
                    room: contact.room,
                    firstname: contact.firstname,
                    lastname: contact.lastname,
                    description: contact.description,
                    domain: contact.domain,
                }
                this.modalTitle = "Modifier le contact"
                this.$refs.contactCreationModal.open()
            },
            handleSaveContact(data) {
                if(data.id){
                    this.updadeContact(data)
                }else{
                    this.addContact(data)
                }
            },
            addContact(data) {
                let contact = new Contact(null, data.room, data.firstname, data.lastname, data.domain, data.description)
                contact.save()
                .then(()=>{
                    logger.log(this.profile.id, "CONTACT ADD", this.profile.email + " a ajouté le contact " + data.firstname + " " + data.lastname)
                })
			},
            updadeContact(contact){
                Contact.updateById(contact.id, {
					room: contact.room,
                    firstname: contact.firstname,
                    lastname: contact.lastname,
                    description: contact.description,
                    domain: contact.domain,
                })
                .then(()=>{
                    logger.log(this.profile.id, "CONTACT UPDATED", this.profile.email + " a modifié le contact " + contact.firstname + " " + contact.lastname)
                })
            },
            deleteContact(contact){
                Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if(result.isConfirmed){
                        Mail.getBySender(contact.id)
                        .then((mails)=>{
                            if(mails && mails.length > 0){
                                Swal.fire({
                                    title: "Impossible de supprimer le contact",
                                    text: "Ce contact est encore lié à un mail",
                                    icon: "error",
                                })
                            }else{
                                contact.delete()
                                logger.log(this.profile.id, "CONTACT DELETED", this.profile.email + " a supprimé le contact " + contact.firstname + " " + contact.lastname)
                            }
                        })
					}
				})
            },
        },
        destroyed() {
            this.unsub.forEach((unsub) => unsub())
        },
	}
</script>
