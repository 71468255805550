<template>
	<v-dialog v-model="displayed" max-width="600px" persistent>
		<v-card>
			<v-card-title>
				<span class="text-h5" v-text="title"></span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-text-field label="Nom" required v-model="data.lastname" />
						</v-col>
						<v-col cols="12">
							<v-text-field label="Prenom" required v-model="data.firstname" />
						</v-col>
						<v-col cols="12">
							<v-text-field label='Domain (ex : "my_domain.com")' required v-model="data.domain" />
						</v-col>
						<v-col cols="12">
							<v-text-field label="Description" required v-model="data.description" />
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="close"> Annuler </v-btn>
				<v-btn color="blue darken-1" text @click="save"> sauvegarder </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
    import logger from "@/assets/functions/logger"
	import Swal from 'sweetalert2/dist/sweetalert2.js'

	export default {
		props: ["data", "title"],
		data() {
			return {
				displayed: false,
			}
		},
		methods: {
			open() {
				this.displayed = true
			},
			close() {
				this.displayed = false
			},
			save() {
				if (this.data.lastname && this.data.firstname && this.data.description && this.data.domain &&  this.data.lastname.trim() != "" && this.data.firstname.trim() != "" && this.data.description.trim() != "" && this.data.domain.trim() != "") {
					this.$emit("save", this.data)
					this.close()
				} else {
					Swal.fire({
						title: "Erreur",
						text: "Les données entrées sont vides ou incorrectes",
						icon: "error",
						confirmButtonText: "OK",
					})
				}
			},
		},
	}
</script>
